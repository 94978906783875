<template>
  <div class="privacy-policy-wrapper">
    <div class="container">
      <div class="head-texts-wrapper">
        <h1>ОФЕРТА</h1>
        <h1>на право использования way2wei.ru</h1>
      </div>
      <div class="notation">
        Дата опубликования текущей версии: 25 января 2023 г.
      </div>
      <h2>Термины и определения</h2>
      <div class="terms">
        <div>Система</div>
        <div>
          сервис Лицензиара, который расположен по адресу:
          <a href="https://way2wei.ru">https://way2wei.ru</a>(включая поддомены)
          и предназначен для определения уровня эмоционального интеллекта и его
          развития.
        </div>
        <div>Личный кабинет</div>
        <div>
          раздел Системы, предназначенный для использования Лицензиатом. Может
          быть использован для хранения информации из Системы, обмена
          сообщениями сторон, а также для иных целей, предусмотренных Тарифом.
        </div>
        <div>Демоверсия</div>
        <div>
          ограниченный перечень функций Системы, доступных Лицензиату после
          регистрации в Системе.
        </div>
        <div>Тариф</div>
        <div>
          объем прав Лицензиата на использование функционала Системы. Виды
          Тарифов доступны на сайте по адресу:
          <a href="https://way2wei.ru">https://way2wei.ru</a>.
        </div>
        <div>Материалы</div>
        <div>
          информация, элементы дизайна, тексты, графические изображения,
          иллюстрации, видео, фото и прочие объекты, размещенные в Системе
          Лицензиаром.
        </div>
      </div>
      <h2>1. Предмет</h2>
      <br />
      1.1. Лицензиар предоставляет Лицензиату неисключительную лицензию на
      использование Системы способами, которые указаны в Договоре, а<br />
      Лицензиат обязуется принять ее и выплатить Лицензиару вознаграждение.<br />
      <h2>2. Регистрация в Системе</h2>
      <br />
      2.1. До прохождения процедуры регистрации Лицензиат обязуется ознакомиться
      с условиями Договора.<br />
      2.2. При регистрации Лицензиат вводит в формы Системы необходимые
      достоверные данные, которые обрабатываются в том виде, в котором они были
      получены.<br />
      2.3. Лицензиар направляет Лицензиату письмо, на указанную им электронную
      почту, с просьбой подтвердить регистрацию в Системе.<br />
      2.4. Лицензиат должен подтвердить регистрацию путем прохождения по ссылке,
      указанной в письме. После подтверждения регистрации в Системе Лицензиат
      получает доступ к Личному кабинету.<br />
      <h2>3. Порядок предоставления лицензии</h2>
      <br />
      3.1. Лицензиат получает доступ к Демоверсии до оплаты лицензионного
      вознаграждения за Тариф с момента регистрации в Системе в ознакомительных
      целях.<br />
      3.2. Для получения доступа к функционалу Системы Лицензиат направляет<br />
      Лицензиару запрос, способами указанными в Системе.<br />
      3.3. Лицензиар предоставляет доступ к функционалу в течение 1 рабочего дня
      с момента оплаты лицензионного вознаграждения за Тариф. После получения
      оплаты Лицензиар направляет Лицензиату по электронной почте уведомление о
      предоставлении доступа.<br />
      3.4. В течение 5 (пяти) рабочих дней с момента получения лицензионного
      вознаграждения Лицензиар направляет Лицензиату по электронной почте
      односторонний акт на предоставление доступа к Системе (далее - Акт).<br />
      3.5. Если в течение 5 (пяти) календарных дней с момента получения Акта не
      направит Лицензиару мотивированные письменные возражения относительно
      предоставления доступа к Системе, обязанность Лицензиара по предоставлению
      доступа к Системе считается исполненной.<br />
      3.6. Право использования функционала Системы считается предоставленным с
      даты направления Лицензиаром уведомления о предоставлении доступа.<br />
      3.7. По истечении срока оплаченного периода доступ к функционалу Системы
      прекращается автоматически.<br />
      <h2>4. Использование Системы</h2>
      <br />
      4.1. Лицензиат вправе использовать Систему следующими способами:<br />
      4.1.1. получение доступа к Личному кабинету и использование<br />
      функционала Личного кабинета Системы;<br />
      4.1.2. воспроизведение функционала Системы, доступного в Тарифе.<br />
      4.2. Лицензиат вправе использовать Систему в соответствии с её
      функциональным назначением.<br />
      4.3. Лицензиар вправе ограничить использование отдельных функций Системы
      для всех или отдельных Лицензиатов.<br />
      4.4. Лицензиар вправе без уведомления Лицензиата проводить
      профилактические работы, которые могут приостановить работу Системы, на
      срок не более 24 часов подряд.<br />
      4.4.1. Если Лицензиару будет необходимо провести более длительные работы,
      он предварительно уведомляет Лицензиата об этом.<br />
      4.4.2. Лицензиат не вправе требовать возмещения убытков за временное
      прекращение доступа к Системе в связи с профилактическими работами.<br />
      <h2>5. Запрещенные действия</h2>
      <br />
      Лицензиату запрещено:<br />
      5.1. Использовать Систему способами, не предусмотренными Договором.<br />
      5.2. Пытаться получить доступ к личной информации другого Лицензиата любым
      способом, в том числе, но не ограничиваясь, путем обмана, злоупотребления
      доверием или взлома Системы.<br />
      5.3. Предпринимать любые действия, в том числе технического характера,
      направленные на нарушение нормального функционирования Системы.<br />
      5.4. Использовать любые технические средства для сбора и обработки
      информации Системы, включая персональные данные других Лицензиатов.<br />
      5.5. Предпринимать попытки обойти установленные технические ограничения
      Системы.<br />
      5.6. Копировать, вносить изменения, подготавливать производные материалы,
      декомпилировать, анализировать с помощью дизассемблера или пытаться
      вскрыть исходный код, каким-либо иным способом изменять Систему.<br />
      5.7. Любым способом вводить других Лицензиатов в заблуждение, в том числе
      выдавать себя за другое лицо, его представителя, без достаточных на то
      прав, в том числе за, а также применять любые другие формы и способы
      незаконного представительства других лиц.<br />
      5.8. Использовать информацию о телефонах, адресах электронной почты для
      рассылки спама, т.е. сообщений коммерческого и некоммерческого
      характера.<br />
      5.9. Без разрешения использовать логотипы и/или товарные знаки
      Лицензиара.<br />
      <h2>6. Вознаграждение Лицензиара</h2>
      <br />
      6.1. Размер вознаграждения Лицензиара определяется на основании выбранного
      Лицензиатом Тарифа.<br />
      6.2. Для продления и смены Тарифа Лицензиату необходимо направить запрос
      Лицензиару в Системе.<br />
      6.3. Вознаграждение Лицензиара не включает возможные комиссии провайдеров
      платежей.<br />
      6.4. Лицензиат уплачивает Лицензиару лицензионное вознаграждение за
      предоставление функционала Системы однократно в форме фиксированного
      платежа.<br />
      6.5. Лицензиат оплачивает лицензионное вознаграждение за выбранный Тариф
      до момента предоставления доступа к Основному функционалу Системы.<br />
      6.6. Лицензионное вознаграждение не облагается НДС в связи с применением
      упрощенной системы налогообложения на основании п. 2 ст. 346.11 главы<br />
      26.2 Налогового кодекса Российской Федерации.<br />
      6.7. Все расчеты сторон по Договору производятся в российских рублях.
      Обязательство Лицензиата по оплате вознаграждения считается исполненным с
      момента зачисления денежных средств на расчетный счет Лицензиара.<br />
      <h2>7. Интеллектуальная собственность</h2>
      <br />
      7.1. Исключительное право на Систему принадлежит Лицензиару.<br />
      7.2. Лицензиар предоставляет доступ к Системе на условиях «как есть» («as
      is»).<br />
      7.3. Лицензиар гарантирует наличие законных прав на Материалы.<br />
      7.4. Лицензиар вправе дорабатывать Материалы, вносить в них изменения, в
      том числе менять содержание, продолжительность.<br />
      7.5. Лицензиату запрещено:<br />
      7.5.1. копировать Материалы, вести их запись полностью или частично любыми
      способами;<br />
      7.5.2. создавать на основе Материалов составные и/или производные
      произведения без письменного согласия Лицензиара;<br />
      7.5.3. предоставлять копии Материалов третьим лицам, в том числе
      посредством их публикации в сети интернет или демонстрации на собственном
      устройстве без доступа к Системе;<br />
      7.5.4. фальсифицировать или удалять любую информацию о правообладателях
      Материалов;<br />
      7.5.5. использовать информацию, полученную в Системе для осуществления
      коммерческой деятельности, извлечения прибыли, либо для использования
      противоречащим закону способом, за исключением навыков, приобретенных на
      основе Материалов;<br />
      7.5.6. размещать в Системе персональные данные третьих лиц, без их
      согласия, в том числе домашние адреса, телефоны, паспортные данные, адреса
      электронной почты;<br />
      7.5.7. при цитировании Материалов Лицензиат обязуется указывать ссылку на
      Систему и автора Материалов, если он указан.<br />
      <h2>8. Защита конфиденциальной информации</h2>
      <br />
      8.1. Лицензиар выполняет обработку персональных данных Лицензиата в целях
      исполнения Договора и согласно требованиям, установленным Федеральным
      законом «О персональных данных» от 27.07.2006 N 152-ФЗ (далее – Закон о
      персональных данных).<br />
      8.2. Порядок обработки и защиты персональных данных определяется Политикой
      конфиденциальности.<br />
      <h2>9. Ответственность</h2>
      <br />
      9.1. В случае, если Лицензиат использует Систему за пределами прав,
      предоставленных по Договору, Лицензиар вправе потребовать от Лицензиата
      выплатить штрафную неустойку в размере 2 % от лицензионного вознаграждения
      за Тариф за каждое нарушение, а также возместить<br />
      Лицензиару все понесенные им убытки.<br />
      9.2. В случае распространения Лицензиатом Материалов или его частей,
      создания на их основе производных или составных произведений и иного
      нарушения интеллектуальных прав Лицензиара или правообладателей, Лицензиар
      вправе потребовать от Лицензиата выплатить штраф в размере<br />
      500 000,00 (пятьсот тысяч) рублей за каждый случай такого нарушения и<br />
      убытки, не покрытые суммой штрафа.<br />
      9.3. Лицензиар не несет ответственности:<br />
      9.3.1. за поломки или другие неполадки компьютера, мобильного устройства,
      любого другого устройства, возникшие во время доступа к Системе;<br />
      9.3.2. за последствия, вызванные утерей или разглашением<br />
      Лицензиатом доступов;<br />
      9.3.3. за ошибки и/или нарушения в эксплуатации Системы, возникшие в
      результате неправомерных действий третьих лиц;<br />
      9.3.4. за сбои и перерывы в работе Системы, вызванные действиями
      государственных органов, включая органы правопорядка, связанных с
      наложением ареста и/или изъятием, или иным препятствованием к доступу
      серверам подрядчиков Лицензиара, на которых размещена Система;<br />
      9.3.5. за приостановку доступа к Системе, Материалам, если это обусловлено
      технической невозможностью использования каналов и сервисов.<br />
      9.3.6. за отсутствие подключения к сети Интернет и/или к серверам на
      территории Лицензиата.<br />
      <h2>10. Урегулирование споров и разногласий</h2>
      <br />
      10.1. Все вопросы и разногласия, которые могут возникнуть между сторонами,
      разрешаются путем переговоров в соответствии с законодательством
      Российской Федерации.<br />
      10.2. Стороны обязуются соблюдать досудебный (претензионный) порядок
      урегулирования споров. Срок для ответа на претензию составляет 10 (десять)
      рабочих дней с момента ее получения стороной.<br />
      10.3. В случае невозможности разрешения спора путем переговоров и в
      претензионном порядке спор передается на рассмотрение в суд по месту
      нахождения Лицензиара.<br />
      <h2>11. Документооборот и коммуникации</h2>
      <br />
      11.1. Стороны признают надлежащим подписание документов путем обмена
      отсканированными копиями в Личном кабинете или по электронной почте,
      указанной в Личном кабинете.<br />
      11.2. Для обмена документами и сообщениями по электронной почте должны
      использоваться адреса сторон, указанные в Системе.<br />
      11.3. Все документы, направляемые сторонам, имеют юридическую силу до
      момента получения сторонами их оригиналов.<br />
      11.4. Каждая сторона несет ответственность за сохранение
      конфиденциальности доступов к своей электронной почте.<br />
      <h2>12. Прочие условия</h2>
      <br />
      12.1. Договор вступает в силу с момента его акцепта и может быть изменен
      или дополнен Лицензиаром в любое время и без уведомления Лицензиата. Новая
      редакция Договора вступает в силу с момента ее размещения в Системе.<br />
      12.2. Лицензиат самостоятельно проверяет условия Договора на предмет их
      изменения и/или дополнения.<br />
      12.3. Продолжение использования Системы после внесения изменений и/или
      дополнений в Договор означает принятие и согласие Лицензиата с такими
      изменениями и/или дополнениями.<br />
      12.4. Прекращение Лицензиатом использования платного функционала Системы
      не является основанием для возврата лицензионного вознаграждения,
      уплаченного за Тариф.<br />
      12.5. Лицензиат, направляя свои персональные данные через формы в Системе,
      дает согласие на их обработку в соответствии с политикой обработки
      персональных данных.<br />
      12.6. Лицензиар вправе направлять Лицензиату уведомления, сведения
      информационного характера по электронной почте или публиковать их в
      Системе.<br />
      12.7. Признание по тем или иным причинам одного или нескольких положений
      Договора недействительными или не имеющими юридической силы, не влияет на
      действительность или применимость остальных положений<br />
      Договора.<br />
      12.8. Все, что не урегулировано Договором, подлежит разрешению в
      соответствии с законодательством Российской Федерации.<br />
      12.9. В связи с тем, что Лицензиат является участником проекта создания и
      обеспечения функционирования инновационного центра «Сколково» № 1123101
      (запись в реестре участников № 1183834 от 13.04.2020 г.), Договор
      заключается с целью исследования и коммерциализации результатов по
      направлению оценки и развития эмоционального интеллекта, а также
      осуществления иных видов деятельности, необходимых для осуществления
      исследований и коммерциализации результатов.<br />
      12.10. Система зарегистрирована в качестве программы для ЭВМ “Way2WEI (Way
      to Working Emotional Intelligence) - платформа оценки и развития
      эмоционального интеллекта человека и корпорации как фундамента гибких
      навыков” (свидетельство о государственной регистрации программы для ЭВМ №
      2022664464 от 29.07.2022).<br />
      12.11. WAY2WEI (Way to Working Emotional Intelligence) - платформа оценки
      и развития эмоционального интеллекта человека и корпорации как фундамента
      гибких навыков зарегистрирована в реестре российского программного
      обеспечения 23.01.2023 за № 16319.<br />
      12.12. Действующая редакция Договора доступна Лицензиату по адресу:
      https://way2wei.ru/document/offer.<br />
      <p class="signature">
        ООО «Лаборатория ЭИ»<br />
        ОГРН 5177746280168<br />
        ИНН / КПП: 7703438337 / 773101001<br />
        Email: connect@eilab.ru<br />
        Юр. адрес: 121205, г. Москва, вн. тер. г. муниципальный округ Можайский,
        тер.<br />
        Сколково инновационного центра, б-р Большой, д. 42, стр. 1, эт. 4,
        помещ. 88<br />
      </p>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
export default {
  name: "offer",
  setup() {
    useHead({
      title: "Договор оферты",
    });
  },
};
</script>

<style lang="scss" scoped>
.privacy-policy-wrapper {
  width: 100%;
  padding: 121px 0 50px;
  font-size: 17px;
  line-height: 24px;
  color: #414352;

  .terms {
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 25px 0;
    margin-top: 35px;
  }

  .signature {
    margin-top: 25px;
  }

  .notation {
    text-align: end;
    font-style: italic;
  }

  .container {
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: 1170px;
  }

  .head-text {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 36px;
    line-height: 1;
    margin: 9px 0;
  }

  h2 {
    font-size: 28px;
    line-height: 1.2;
    margin: 30px 0 15px;
  }

  p {
    margin: 0 0 15px;
  }

  ul,
  ol {
    margin: 0 0 15px;
    padding: 0 0 0 40px;
  }

  @media (max-width: 1189px) {
    padding: 140px 20px 30px;

    .container {
      padding: 0 15px;
    }

    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
  }

  @media (max-width: 764px) {
    padding: 80px 0 30px;

    .terms {
      grid-template-columns: 100%;
    }
  }
}
</style>